<template>
    <div class="pathology-validation">
        <div class="row">
            <div class="col50">
                <h1 v-html="$t('patient.acceptance.title')"></h1>
                <p v-html="$t('patient.acceptance.text')"></p>
                <div :class="['accordion-container', { 'active' : step === 1} ]" >
                    <div class="accordion-title" @click="changeStep(1)" >
                        <h2>{{$t('patient.acceptance.title1')}}</h2>
                        <div class="accordion-icon">+</div>
                    </div>
                    <div class="accordion-text" v-if="step === 1">
                        <p v-html="$t('patient.acceptance.text1')"></p>
                    </div>
                </div>
                <div :class="['accordion-container', { 'active' : step === 2} ]" >
                    <div class="accordion-title" @click="changeStep(2)">
                        <h2>{{$t('patient.acceptance.title2')}}</h2>
                        <div class="accordion-icon">+</div>
                    </div>
                    <div class="accordion-text" v-if="step === 2">
                        <p v-html="$t('patient.acceptance.text2')"></p>
                    </div>
                </div>
                <div :class="['accordion-container', { 'active' : step === 3} ]" >
                    <div class="accordion-title" @click="changeStep(3)">
                        <h2>{{$t('patient.acceptance.title3')}}</h2>
                        <div class="accordion-icon">+</div>
                    </div>
                    <div class="accordion-text" v-if="step === 3">
                        <p v-html="$t('patient.acceptance.text3')"></p>
                    </div>
                </div>
                <div :class="['accordion-container', { 'active' : step === 4} ]" >
                    <div class="accordion-title" @click="changeStep(4)">
                        <h2>{{$t('patient.acceptance.title4')}}</h2>
                        <div class="accordion-icon">+</div>
                    </div>
                    <div class="accordion-text" v-if="step === 4">
                        <p v-html="$t('patient.acceptance.text4')"></p>
                    </div>
                </div>
                <div :class="['accordion-container', { 'active' : step === 5} ]" >
                    <div class="accordion-title" @click="changeStep(5)">
                        <h2>{{$t('patient.acceptance.title5')}}</h2>
                        <div class="accordion-icon">+</div>
                    </div>
                    <div class="accordion-text" v-if="step === 5">
                        <p v-html="$t('patient.acceptance.text5')"></p>
                    </div>
                </div>
                <div :class="['accordion-container', { 'active' : step === 6} ]" >
                    <div class="accordion-title" @click="changeStep(6)">
                        <h2>{{$t('patient.acceptance.title6')}}</h2>
                        <div class="accordion-icon">+</div>
                    </div>
                    <div class="accordion-text" v-if="step === 6">
                        <p v-html="$t('patient.acceptance.text6')"></p>
                    </div>
                </div>
                <div :class="['accordion-container', { 'active' : step === 7} ]" >
                    <div class="accordion-title" @click="changeStep(7)">
                        <h2>{{$t('patient.acceptance.title7')}}</h2>
                        <div class="accordion-icon">+</div>
                    </div>
                    <div class="accordion-text" v-if="step === 7">
                        <p v-html="$t('patient.acceptance.text7')"></p>
                    </div>
                </div>
                <div class="accordion-line"></div>
                <div class="row-acceptance">
                    <div :class="['acceptance' , {active : acceptance == true}]" @click="acceptance = !acceptance"></div>
                    <p>{{ $t('patient.acceptance.checkbox') }}</p>
                </div>
            </div>
            <div class="col50 col-img" v-if="imagePath">
                <img :src="`${this.$store.state.URL_ASSESTS}/${imagePath}`" alt="pathology" />
            </div>
        </div>
        <div class="row-submit">
            <div :class="['btn', 'btn-submit', { 'waiting' : !acceptance}]"  @click="accept">{{ $t('patient.continue') }}</div>
        </div>

    </div>
</template>

<script>
import store from './../../store'
import axios from 'axios'
import router from './../../router'

export default {
    name : 'PathologyValidation',
    data() {
        return {
            acceptance : false,
            name : null,
            imagePath : null,
            step : -1
        }
    },
    methods : {
        accept() {
            if(this.acceptance) {
                let config = {
                    validateStatus: () => true,
                }
                if(store.getters.getToken) {
                    config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
                }

                let params =  {
                    id : this.$route.params.id
                }
                axios
                    .put(store.getters.getURL +"monitoring/acceptance" , params  , config)
                    .then(function (response) {
                        if(response.status === 200){
                            store.dispatch('addNotif', {type : 1, content : 24})
                            router.push({ name : 'Pathology', params : { id : params.id}})                            
                        }else {
                             store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                        }
                    })
                    .catch((err) => {
                        store.dispatch('addNotif', {type : 2, content : err})
                    });
                }
            },
            changeStep(step) {
                if(this.step != step) {
                    this.step = step;
                }else {
                    this.step = -1;
                }
            }
    },
    mounted() {
        let config = {
             validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this

        axios
            .get(store.getters.getURL +"pathology/" + this.$route.params.id , config)
            .then(function (response) {
                vm.name = response.data.name
                vm.imagePath = response.data.image_path
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
    }
}
</script>

<style lang="scss" scoped>
    .pathology-validation {
        background: #fff;
        border-radius:10px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.5);
        margin:80px auto;
        padding: 20px 40px;
        width:$boxWidth;
        max-width: calc(90% - 80px);
        position:relative;
    }

    .row {
        align-items: center;
    }

    .col-img {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        width: 650px;
        max-width: 90%;
        height:auto;
    }

    h1 {
        font-size:25px;
        margin:0 0 20px 0;
        color:$mainColor;
        font-family: $fontBlack;
        letter-spacing: 1px;
    }

    p {
        font-size:15px;
        color:$textColor;
        text-align: justify;
    }

    .row-acceptance {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top:40px
    }

    .acceptance {
        width:30px;
        height:30px;
        min-width: 30px;
        border:2px solid $mainColor;
        border-radius: 10px;
        margin-right:20px;
        cursor: pointer;
        transition: 0.5s;
    }

    .acceptance.active {
        background: $mainColor;
    }

    .row-acceptance p {
        margin:0;
    }

    .row-submit {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .btn-submit {
        width:200px;
    }

    .acceptance-errror {
        color:$error;
        text-align: center;
        margin-top:10px;
    }

    .btn-submit.waiting {
        pointer-events: none;
        opacity:0.5;
    }

    .accordion-title {
        cursor: pointer;
        padding:20px 10px;
        border-top:1px solid $mainColor;
        display: flex;
        justify-content: space-between;
        transition:0.5s;
    }

    .accordion-title h2 {
        font-size:18px;
        font-weight:400;
        transition:0.5s;
    }

    .accordion-title h2:hover {
        color:$mainColor;
    }

    .accordion-container.active .accordion-title h2 {
        color:$mainColor;
    }

    .accordion-container.active .accordion-title {
        background:$inputBackground;
    }
    
    .accordion-icon {
        font-size:25px;
        font-weight:700;
        transition:0.5s;
        margin-left:20px;
    }

    .accordion-text p {
        text-align: justify;
        margin:20px 0 30px 0;
        padding:0 10px;
    }

    .accordion-container.active .accordion-icon {
        transform: rotate(45deg);
        color:$mainColor;
    }

    .accordion-line {
        height:1px;
        width:100%;
        background:$mainColor;
    }

    @media screen and (max-width: 768px) {
        .col-img {
            display: none;
        }

        .col50 {
            width:100%;
        }

        h1 {
            font-size:18px;
        }

        .pathology-validation {
            padding: 20px;
            max-width: calc(90% - 40px);
        }

        .accordion-title h2 {
            font-size:16px;
        }
    }
</style>

